<template>
  <div>
    <passContainer
      :sections="sections"
      :activeSection="activeSection"
    ></passContainer>
    <titleForm
      title="Registrando una evaluación médica ocupacional"
      :strong="branchName"
    ></titleForm>
    <form
      id="EmployeeForm"
      class="mainForm mb-3 needs-validation"
      v-bind:class="{ 'was-validated': showValidate }"
    >
      <div class="xScroll d-flex">
        <first
          :submitValidate="submitStart"
          :sectionName="sections[0]"
          sectionPosition="0"
          submitName="Registrar Evaluación Médica"
          @moveForm="moveForm($event)"
          @submitForm="submit()"
        ></first>
      </div>
    </form>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.xScroll {
  margin-left: 0%;
  transition: 0.3s ease-in-out;
}
.mainForm {
  z-index: 8;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
</style>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import passContainer from "@/components/form/passContainer";
import titleForm from "@/components/form/Title";

import { validateFormData } from "@/modules/tools";

import first from "@/views/employee/form/eval/1";

export default {
  name: "Employee Evaluation Create",
  data() {
    return {
      sections: ["Datos Básicos"],
      activeSection: "",
      submitStart: false,
      showValidate: false,
      branchName: ""
    };
  },
  components: {
    Legal,
    passContainer,
    titleForm,
    first
  },
  created() {
    this.activeSection = this.sections[0];
    const store = new Storage();
    store.getData("branch", this.$route.params._id).then(branch => {
      store.getData("city", branch.city).then(city => {
        this.branchName = city.name;
      });
    });
  },
  methods: {
    submit() {
      const EmployeeForm = document.getElementById("EmployeeForm");
      const input = EmployeeForm.getElementsByTagName("input");
      const select = EmployeeForm.getElementsByTagName("select");
      let submit = true;

      this.submitStart = !this.submitStart;
      this.showValidate = true;

      for (let i = 0; i < input.length; i++) {
        if (!validateFormData(input[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < select.length; i++) {
        if (!validateFormData(select[i])) {
          submit = false;
        }
      }

      if (submit) {
        this.sendData(EmployeeForm);
      }
    },
    sendData(form) {
      const store = new Storage();
      const formData = new FormData(form);

      Swal.fire({
        icon: "warning",
        title: "Registrando evaluación médica",
        html:
          "Por favor espere mientras se registra la evaluación médica</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressDataForm" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      const data = {
        employee: formData.get("employeeList"),
        date: new Date(formData.get("date")).getTime(),
        concept: formData.get("conceptList"),
        branch: this.$route.params._id,
        company: this.companyId
      };

      store
        .insertData("optima", "medicalEvaluation", [data])
        .then(medicalEvaluationData => {
          store.getData("file").then(fileList => {
            const fileInput = formData.get("fileInput");

            if (fileInput.name !== "") {
              let parent;
              fileList.forEach(file => {
                if (
                  file.name === "Evaluaciones Médicas Ocupacionales" &&
                  file.branch === this.$route.params._id
                ) {
                  parent = file._id;
                }
              });

              const fileData = {
                expiration: new Date().setFullYear(
                  new Date().getFullYear() + 1
                ),
                responsable: formData.get("employeeList"),
                createdBy: formData.get("employeeList"),
                branch: this.$route.params._id,
                extension: formData.get("extension"),
                name: formData.get("fileName"),
                parent: parent,
                size: parseInt(formData.get("fileSize"))
              };
              console.log(fileData);
              store
                .insertData("optima", "file", [fileData])
                .then(data => {
                  const progressBranch = document.getElementById(
                    "progressDataForm"
                  );
                  const percentUpdate = (loaded, total, percent) => {
                    percent -= 5;
                    progressBranch.innerText = percent.toString() + "%";
                    progressBranch.style.width = percent.toString() + "%";
                  };

                  formData.append("_id", data[0]._id);
                  formData.append("parent", parent);
                  store
                    .uploadFile(
                      "file",
                      data[0]._id,
                      "optima/file/upload",
                      formData,
                      percentUpdate
                    )
                    .then(res => {
                      store
                        .getData(
                          "medicalEvaluation",
                          medicalEvaluationData[0]._id
                        )
                        .then(medicalEvaluation => {
                          medicalEvaluation.certificate = data[0]._id;
                          delete medicalEvaluation.registrationDate;
                          delete medicalEvaluation.lastUpdate;
                          store
                            .updateData(
                              "medicalEvaluation",
                              [medicalEvaluation],
                              true,
                              "optima"
                            )
                            .then(() => {
                              this.endSubmit(true);
                            });
                        });
                    })
                    .catch(error => {
                      console.log(error);
                      this.endSubmit(false);
                    });
                })
                .catch(error => {
                  console.log(error);
                  this.endSubmit(false);
                });
            } else {
              this.endSubmit(true);
            }
          });
        })
        .catch(error => {
          console.log(error);
          this.endSubmit(false);
        });
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Evaluación médica registrada",
          text: "Todos los datos han sido sincronizados"
        }).then(result => {
          this.$router.go(-1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al registrar la evaluación médica",
          text:
            "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
        }).then(result => {
          this.$router.go(-1);
        });
      }
    }
  }
};
</script>
