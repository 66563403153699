<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="employeeList" class="form-label">* Empleado</label>
        <select
          name="employeeList"
          class="form-select"
          v-model="employee"
          required
        >
          <option
            v-for="employee in employeeList"
            v-bind:value="employee._id"
            :key="employee._id"
            >{{ employee.name }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="date" class="form-label">* Fecha del Examen Médico</label>
        <input type="date" class="form-control" name="date" required />
        <div class="invalid-feedback">
          Por favor ingrese una fecha del examen
        </div>
      </div>
      <div class="mb-3">
        <label for="conceptList" class="form-label">* Concepto Médico</label>
        <select name="conceptList" class="form-select" required>
          <option value="APTO">Apto</option>
          <option value="APTO_CON_RECOMENDACIONES"
            >Apto con recomendaciones</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="fileInput" class="form-label"
          >* Certificado de Aptitud Laboral</label
        >
        <input
          id="mainInputFile"
          class="form-control"
          type="file"
          name="fileInput"
          accept=".pdf"
          @change="checkFile($event.target)"
          required
        />
        <div class="invalid-feedback">
          Por favor seleccione un archivo
        </div>
      </div>
      <input type="hidden" name="extension" v-model="extension" />
      <input type="hidden" name="fileSize" v-model="fileSize" />
      <input type="hidden" name="fileName" :value="fileName" />
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-primary shadow-primary"
          @click.prevent="submit()"
        >
          {{ submitName }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      employeeList: [],
      employee: "",
      extension: "",
      fileSize: "",
      enableExtensions: ["PDF"]
    };
  },
  mounted() {
    store.getData("employee").then(employeeList => {
      employeeList.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      employeeList.forEach(employee => {
        if (employee.branch === this.$route.params._id) {
          this.employeeList.push(employee);
        }
      });
    });
  },
  computed: {
    fileName: function() {
      let fileName = "";
      this.employeeList.forEach(employee => {
        if (employee._id === this.employee) {
          fileName = employee.ID + " - " + employee.name;
        }
      });
      return fileName;
    }
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    },
    checkFile(fileInput) {
      const file = fileInput.files[0];

      let fileName = file.name.split(".");
      this.extension = fileName.pop();
      fileName = fileName.join(".");

      this.name = fileName;
      this.extension = this.extension.toUpperCase();

      this.fileSize = file.size;

      if (file.size > 2500000000) {
        Swal.fire({
          icon: "error",
          title: "Archivo no permitido",
          text: "El archivo no debe superar un tamaño de 4GB"
        }).then(() => {
          document.getElementById("mainInputFile").value = "";
          this.name = "";
        });
      }

      if (!this.enableExtensions.includes(this.extension)) {
        Swal.fire({
          icon: "error",
          title: "Archivo no permitido",
          text: "No se permite subir archivos con extensión: " + this.extension
        }).then(() => {
          document.getElementById("mainInputFile").value = "";
          this.name = "";
        });
      }
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.name !== undefined && val.name !== null) {
              this.name = val.name;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Employee Evaluation Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
